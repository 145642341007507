import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PortalCliente from "./pages/PortalCliente/";
import Login from "./pages/Login"; //Não retirar, isso aqui ta trazendo uns estilos eu acho
import LoginCliente from "./pages/LoginCliente";
import Logout from "./pages/Logout";
import Auth from "./pages/Auth";
import Base from "./base.js";
import DadosCadastrais from "./pages/Cadastro/DadosCadastrais";
import Documentacao from "./pages/Cadastro/Documentacao";
import DocumentosAssinados from "./pages/Cadastro/DocumentosAssinados";
import MeusChamados from "./pages/MeuImovel/MeusChamados";
import MeusPagamentos from "./pages/Widgets/MeusPagamentos";
import Chamado from "./pages/MeuImovel/MeusChamados/Chamado/chamado";
import Relacionamento from "./pages/Relacionamento";
import NovaIndicacao from "./pages/Relacionamento/NovaIndicacao";
import GuLogin from "./pages/Auth/GuLogin";
import { LoginClienteViaLink } from "./pages/LoginClienteViaLink/index.js";
import { isDesktop } from "react-device-detect";
import { ConfiguracoesVisuaisContextContext } from "./contexts/ConfiguracoesVisuaisContext.js";

export default function Routes({}) {
  const history = useHistory();
  const { aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)

  return (
    <Switch>
      <Route path="/login/:slug" exact component={LoginCliente} />
      <Route path="/login/:slug/:token" exact component={LoginClienteViaLink} />
      <Route path="/sair" exact component={Logout} />
      <Route path="/gu-login/:token" exact component={GuLogin} />
      <Route path="/auth" exact component={Auth} />
      <Base
        history={history}
        conteudo={
          <>
            <div style={{ height: (isDesktop || !aplicarEstilosMobile ? "100%" : "auto") }}>
              <>
                <Route path="/" exact component={PortalCliente} />
                <Route
                  path="/dados_cadastrais"
                  exact
                  component={DadosCadastrais}
                />
                <Route path="/documentacao" exact component={Documentacao} />
                <Route
                  path="./assinados"
                  exact
                  component={DocumentosAssinados}
                />
                <Route path="/meus_chamados" exact component={MeusChamados} />
                <Route
                  path="/meus_pagamentos"
                  exact
                  component={MeusPagamentos}
                />
                <Route path="/chamado" exact component={Chamado} />
                <Route
                  path="/relacionamento"
                  exact
                  component={Relacionamento}
                />
                <Route path="/nova_indicacao" exact component={NovaIndicacao} />
              </>
            </div>
          </>
        }
      ></Base>
    </Switch>
  );
}
