import { trackPromise } from "react-promise-tracker";
import crm from "./crm";

const ticketService = {
  index: (id) => {
    return crm.get("/ticket/" + id);
  },
  store: (dados) => {
    return crm.post(`/ticket`, { ticket: dados });
  },
  getMensagemByTicket: (id) => {
    return crm.get("/ticket/" + id + "/mensagens");
  },
  storeMensagem: (id, dados) => {
    let uri = "/ticket/" + id + "/mensagem";
    return crm["post"].call(crm, uri, { mensagem: dados });
  },
  getNotaInternaByTicket: (id) => {
    return crm.get("/ticket/" + id + "/notas");
  },
  storeNotaInterna: (id, dados) => {
    let uri = "/ticket/" + id + "/nota";
    return crm["post"].call(crm, uri, { nota: dados });
  },
  update: (id_ticket, data) => {
    return trackPromise(crm.put(`ticket/${id_ticket}`, data))
  },
  buscarChamadosAsssitenciaTecnica: (id) => {
    return crm.get("/ticket/vistoria/assistencia-tecnica/" + id);
  },
  getDetalhamentoPorMotivo: (idMotivo) => {
    return crm.get(`/assistencia-tecnica/motivo/${idMotivo}/detalhamentos`);
  },
  buscarChamadosAreaComum: (dados) => {
    return crm.get('/ticket', { params: dados })
  },  
  getMobuss:(cpf) => {
    return crm.get(`/ticket/integracao/${cpf}`)
  },
  buscarConfiguracoesCriacao: () => {
    return crm.get(`/ticket/configuracoes/criacao`)
  }
};

export default ticketService;
