import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import { useForm } from "react-hook-form";
import { Alert, Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../../components/InputTextMask";
import formatDommus from "../../../../helpers/format";
import moment from "moment/moment";
import guService from "../../../../services/GuService";
import { isMobile } from "react-device-detect";
import { ConfiguracoesVisuaisContextContext } from "../../../../contexts/ConfiguracoesVisuaisContext";

export function ModalSimuladorFinanceiro({
    valoresRenegociacao,
    closeModal,
    idWidget,
    negociacaoParametros
}) {
    const { register, setValue, handleSubmit, watch, errors, clearErrors, getValues } = useForm();
    const tipoPagamentoWatch = watch("tipo_pagamento");
    const [percentualEntrada, setPercentualEntrada] = useState(0);
    const [percentualDescontos, setPercentualDescontos] = useState({});
    const [valorFinalSaldoDevedor, setValorFinalSaldoDevedor] = useState(0);
    const [checkboxCiencia, setCheckboxCiencia] = useState(false);
    const [optionsParcelas, setOptionsParcelas] = useState([]);
    const percentuaisEntrada = useMemo(() => {
        return [15, 20, 30, 40, 50, 70];
    }, []);
    const { aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)
    const handleOnSubmit = (data) => {

        let valorFinal = "";
        let valorEntrada = "";
        let cpf = formatDommus.formatarParaMascaraCpf(guService.getLocalState("CPF"));

        if(percentualEntrada === 100){
            valorFinal = `Valor final: ${formatarParaMascaraMoedaComSeparadorDeMilhares(valorFinalSaldoDevedor)}\n${percentualDescontos?.encargos ? `Com isenção ${percentualDescontos?.encargos}% dos encargos.` : ""}\n${percentualDescontos?.valor ? `Com desconto de ${percentualDescontos?.valor}% do valor corrigido.` : ""}`;
        }else if(data?.parcela){
            valorFinal = `Parcela: ${data?.parcela}`;
            valorEntrada = `Valor da entrada: R$${data?.valor_entrada}\n`
        }

        let mensagem = `*Quero renegociar minha dívida*\nNome: ${guService.getLocalState("nomeProponente")}\nCPF: ${cpf}\n*Saldo devedor:* R$${formatarParaMascaraMoedaComSeparadorDeMilhares(valoresRenegociacao?.saldo_total_devedor)}\nTipo pagamento: ${data?.tipo_pagamento === "cartao_credito" ? "Cartão de Crédito" : "Boleto Bancário"}\n${valorEntrada}${valorFinal}`;
        const whatsappURL = `https://api.whatsapp.com/send?phone=${negociacaoParametros?.contato_whatsapp}&text=${encodeURIComponent(mensagem)}`;
        window.open(whatsappURL, '_blank');
        closeModal();
    };

    const handleAlterarEntrada = (porcentagem) => {
        let valorEntradaPercentual =
            (valoresRenegociacao?.saldo_total_devedor * porcentagem) / 100;
        if (valorEntradaPercentual) {
            clearErrors("valor_entrada");
        }
        setValue(
            "valor_entrada",
            formatDommus.formatarRealMoedaInput(
                valorEntradaPercentual.toFixed(2).toString()
            )
        );
        setPercentualEntrada(porcentagem);
    };

    const handleCalcularPercentualPeloValor = (valor) => {
        valor = formatDommus.formatarDeMascaraMoeda(valor);
        let valorPercentual =
            (valor * 100) / valoresRenegociacao?.saldo_total_devedor;
        setPercentualEntrada(valorPercentual);
    };

    const calculaDescontoEncargos = (percentualDesconto = 0) => {
        let valorEncargos =
            formatDommus.arredondarDecimal(
                valoresRenegociacao?.valor_total_acrescimo
            ) ??
            formatDommus.arredondarDecimal(
                valoresRenegociacao?.saldo_total_devedor
            ) -
                formatDommus.arredondarDecimal(
                    valoresRenegociacao?.valor_total_devedor
                );
        return (valorEncargos * percentualDesconto) / 100;
    };

    function filtrarParametrosPorTipoPagamento(parametros) {
        return tipoPagamentoWatch && (!parametros.tipoPagamento || (parametros.tipoPagamento === tipoPagamentoWatch))
    }

    useEffect(() => {
        setValue('parcela', null)
        if (percentualEntrada === 100) {
            const paramentrosAVista = negociacaoParametros?.parametro.find(
                (item) => item.tipo === "a_vista"
            );
            if (paramentrosAVista?.parametros.length === 1) {
                const parametros = paramentrosAVista?.parametros[0];
                const saldoDevedor = formatDommus.arredondarDecimal(
                    valoresRenegociacao?.saldo_total_devedor
                );
                let descontos = { encargos: 0, valor: 0 };
                let saldoFinal = 0;

                if (parametros?.percent_desc_encargos) {
                    saldoFinal =
                        saldoDevedor -
                        calculaDescontoEncargos(
                            parametros?.percent_desc_encargos
                        );
                    descontos.encargos = parametros?.percent_desc_encargos;
                }
                if (parametros?.percent_desc_valor) {
                    let valorProporcionalValor =
                        (valoresRenegociacao?.valor_total_devedor *
                            parametros?.percent_desc_valor) /
                        100;
                    saldoFinal -= valorProporcionalValor;

                    descontos.valor = parametros?.percent_desc_valor;
                }

                setValorFinalSaldoDevedor(saldoFinal);
                setPercentualDescontos(descontos);
            } else {
            }
        } else {
            if (
                negociacaoParametros?.parametro &&
                negociacaoParametros?.parametro.length
            ) {
                let optionsParcelasTemp = [];
                let parametros = [];
                negociacaoParametros.parametro.forEach((parametro) => {
                    if (
                        parametro?.data_limite &&
                        moment(parametro.data_limite) > moment() &&
                        !optionsParcelasTemp.length
                    ) {
                        parametro.parametros.filter(filtrarParametrosPorTipoPagamento).forEach((item) => {
                            if (
                                Number(percentualEntrada) >=
                                item?.percent_entrada
                            ) {
                                let novoValorEncargo = calculaDescontoEncargos(
                                    item?.percent_desc_encargos
                                );
                                let novoSaldoDevedor = formatDommus.arredondarDecimal(valoresRenegociacao.saldo_total_devedor) -
                                    novoValorEncargo - 
                                    formatDommus.formatarDeMascaraMoeda(getValues('valor_entrada'))

                                for (
                                    let index = 1;
                                    index <= item?.num_parcelas;
                                    index++
                                ) {
                                    let msg = `${index}x de R$${formatarParaMascaraMoedaComSeparadorDeMilhares(novoSaldoDevedor / index)} - ${item?.percent_desc_encargos? `Desconto de ${item?.percent_desc_encargos}% em encargos`: ""}`;
                                    optionsParcelasTemp.push(
                                        <option
                                            className="option-parcelas"
                                            value={msg}
                                        >
                                            {msg}
                                        </option>
                                    );
                                }
                            }
                        });
                    } else if (
                        parametro?.parametros &&
                        parametro?.valor_maximo &&
                        valoresRenegociacao.saldo_total_devedor <
                            parametro.valor_maximo &&
                        !optionsParcelasTemp.length
                    ) {
                        let numParcela = 1;
                        parametro.parametros.filter(filtrarParametrosPorTipoPagamento).forEach((item) => {
                            if (
                                Number(percentualEntrada) >=
                                item?.percent_entrada
                            ) {
                                let novoValorEncargo = calculaDescontoEncargos(
                                    item?.percent_desc_encargos
                                );
                                let novoSaldoDevedor = formatDommus.arredondarDecimal(valoresRenegociacao.saldo_total_devedor) - 
                                    novoValorEncargo - 
                                    formatDommus.formatarDeMascaraMoeda(getValues('valor_entrada'))

                                for (
                                    ;
                                    numParcela <= item?.num_parcelas;
                                    numParcela++
                                ) {
                                    let msg = `${numParcela}x de R$${formatarParaMascaraMoedaComSeparadorDeMilhares(novoSaldoDevedor / numParcela)} - ${item?.percent_desc_encargos? `Desconto de ${item?.percent_desc_encargos}% dos encargos`: ""}`;
                                    optionsParcelasTemp.push(
                                        <option
                                            className="option-parcelas"
                                            value={msg}
                                        >
                                            {msg}
                                        </option>
                                    );
                                }
                            }
                        });
                    } else if (
                        parametro?.parametros &&
                        parametro?.valor_minimo &&
                        valoresRenegociacao.saldo_total_devedor >
                            parametro.valor_minimo &&
                        !optionsParcelasTemp.length
                    ) {
                        let numParcela = 1;
                        parametro.parametros.filter(filtrarParametrosPorTipoPagamento).forEach((item) => {
                            if (
                                Number(percentualEntrada) >=
                                item?.percent_entrada
                            ) {
                                let novoValorEncargo = calculaDescontoEncargos(
                                    item?.percent_desc_encargos
                                );
                                let novoSaldoDevedor = formatDommus.arredondarDecimal(valoresRenegociacao.saldo_total_devedor) - 
                                    novoValorEncargo - 
                                    formatDommus.formatarDeMascaraMoeda(getValues('valor_entrada'))
                                    
                                for (
                                    ;
                                    numParcela <= item?.num_parcelas;
                                    numParcela++
                                ) {
                                    let msg = `${numParcela}x de R$${formatarParaMascaraMoedaComSeparadorDeMilhares(novoSaldoDevedor / numParcela)} - ${item?.percent_desc_encargos? `Desconto de ${item?.percent_desc_encargos}% dos encargos`: ""}`;
                                    optionsParcelasTemp.push(
                                        <option
                                            className="option-parcelas"
                                            value={msg}
                                        >
                                            {msg}
                                        </option>
                                    );
                                }
                            }
                        });
                    }
                });

                setOptionsParcelas(optionsParcelasTemp);
            }
        }
    }, [percentualEntrada, tipoPagamentoWatch]);

    return (
        <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="modal-simulador-content"
        >
            <Alert variant="warning" className="simulador-messagem">
                <strong>ATENÇÃO:</strong> Os dados de simulação podem sofrer
                alterações. Os valores corretos serão confirmados na contratação
                da renegociação.
            </Alert>
            <div className="item-section">
                <div className="item-value-section">
                    <span className="label-item">Valor em atraso:</span>
                    <spam className="saldo-devedor">
                        R${" "}
                        {formatarParaMascaraMoedaComSeparadorDeMilhares(
                            valoresRenegociacao?.saldo_total_devedor
                        )}
                    </spam>
                </div>
            </div>
            {(isMobile && aplicarEstilosMobile && (<>
                <Row className="item-value-section">
                    <Col>
                        <span className="label-item">
                            Informe o valor da ENTRADA:
                        </span>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>R$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="text-right"
                                aria-label="Valor da entrada"
                                ref={register({ required: true })}
                                name="valor_entrada"
                                onChange={(event) => {
                                    setValue(
                                        "valor_entrada",
                                        formatDommus.formatarRealMoedaInput(
                                            event.target.value
                                        )
                                    );
                                }}
                                onBlur={(event) => {
                                    handleCalcularPercentualPeloValor(
                                        event.target.value
                                    );
                                }}
                            />
                            {errors && errors.valor_entrada && (
                                <small className="form-text text-danger">
                                    É necessário um valor de entrada
                                </small>
                            )}
                        </InputGroup>
                        <span className="mensagem-porcentagem-entrada">
                            Equivale a{" "}
                            {formatDommus.formatarParaMascaraPercent(
                                percentualEntrada
                            )}
                            % do valor devido
                        </span>
                    </Col>
                </Row>
                <Row className="btns-entradas-section">
                    <Col>
                    {percentuaisEntrada.map((item, index) => {
                        return (
                            <Button
                                variant="secondary"
                                kye={index}
                                className={`btn-percentual-entrada mr-3 mb-3 ${
                                    Number(item.toFixed(2)) ===
                                    Number(percentualEntrada.toFixed(2))
                                        ? "selecionado"
                                        : ""
                                }`}
                                onClick={() => {
                                    handleAlterarEntrada(item);
                                }}
                            >
                                {item}%
                            </Button>
                        );
                    })}
                    <Button
                        variant="secondary"
                        className={`btn-percentual-entrada mr-3 mb-3 ${
                            Number(percentualEntrada.toFixed(0)) === 100
                                ? "selecionado"
                                : ""
                        }`}
                        onClick={() => {
                            handleAlterarEntrada(100);
                        }}
                    >
                        à Vista
                    </Button>
                    </Col>
                </Row>
                <Row className="tipo-pagamento-section">
                    <Col>
                        <span>Informe a FORMA DE PAGAMENTO:</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="btn-tipo-pagamento-section">
                            <div className="btn-tipo-pagamento-content">
                                <input
                                    type="radio"
                                    id="checkbox-cartao-credito"
                                    ref={register({ required: true })}
                                    name="tipo_pagamento"
                                    value="cartao_credito"
                                />
                                <label
                                    htmlFor="checkbox-cartao-credito"
                                    className={`checkbox-tipo-pagamento-label ${
                                        tipoPagamentoWatch === "cartao_credito"
                                            ? "checked"
                                            : ""
                                    }`}
                                >
                                    CARTÃO DE CRÉDITO
                                </label>
                            </div>
                            <div className="btn-tipo-pagamento-content">
                                <input
                                    type="radio"
                                    id="checkbox-boleto"
                                    ref={register({ required: true })}
                                    name="tipo_pagamento"
                                    value="boleto_bancario"
                                />
                                <label
                                    htmlFor="checkbox-boleto"
                                    className={`checkbox-tipo-pagamento-label ${
                                        tipoPagamentoWatch === "boleto_bancario"
                                            ? "checked"
                                            : ""
                                    }`}
                                >
                                    BOLETO BANCÁRIO
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pagamento-a-vista-section">
                    <Col>
                        {percentualEntrada && tipoPagamentoWatch ? (<>
                                {Number(percentualEntrada.toFixed(0)) === 100 ? (<>
                                    <Row className="valor-final-section mb-3">
                                        <Col>
                                            <span className="label"><strong>Valor</strong> com desconto para pagamento à vista:</span>
                                            <span className="valor-final">
                                                R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(valorFinalSaldoDevedor)}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="descontos-section">
                                                {percentualDescontos?.encargos && (
                                                    <span>
                                                        * Isenção de{" "}
                                                        {percentualDescontos?.encargos}% dos
                                                        encargos.
                                                    </span>
                                                )}
                                                {percentualDescontos?.valor && (
                                                    <span>
                                                        * Desconto de{" "}
                                                        {percentualDescontos?.valor}% do valor
                                                        corrigido.
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </>) : (<>
                                    <Row className="parcelas-section">
                                        <Col>
                                            <span>Selecione a parcela desejada:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="parcelas.ControlSelect1" className="mb-0">
                                                <Form.Control
                                                    as="select"
                                                    className="select-parcelas"
                                                    name="parcela"
                                                    ref={register({required: Number(percentualEntrada.toFixed(0)) !== 100})}
                                                >
                                                    <option hidden value="">
                                                        Parcelamentos disponíveis
                                                    </option>
                                                    {optionsParcelas.map((item, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {item}
                                                            </Fragment>
                                                        );
                                                    })}
                                                </Form.Control>
                                                {errors && errors?.parcela && (
                                                    <small className="form-text text-danger">
                                                        É necessário a seleção do número de parcelas!
                                                    </small>
                                                )}
                                                {!optionsParcelas.length ? (
                                                    <small className="form-text text-danger">
                                                        Valor de entrada mínima não atingido!
                                                    </small>
                                                ) : (
                                                    <></>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>)}
                        </>) : (
                            <></>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            id={`default-proceguir`}
                            label="Estou ciente que os valores podem sofre alteração!"
                            onChange={(event) => {
                                setCheckboxCiencia(event.target.checked);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                         <div className="btns-conclusao-section">
                            <Button variant="success" type="submit" disabled={!checkboxCiencia || !negociacaoParametros?.contato_whatsapp} className="mr-3">
                                Quero fechar acordo
                            </Button>
                            <Button variant="secondary" type="button"  onClick={() => closeModal()}>
                                Cancelar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </>)) || <>
                <div className="item-section">
                    <div className="valor-entrada-section">
                        <div className="item-entrada-section">
                            <div className="item-value-section">
                                <span className="label-item">
                                    Informe o valor da ENTRADA:
                                </span>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>R$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        className="text-right"
                                        aria-label="Valor da entrada"
                                        ref={register({ required: true })}
                                        name="valor_entrada"
                                        onChange={(event) => {
                                            setValue(
                                                "valor_entrada",
                                                formatDommus.formatarRealMoedaInput(
                                                    event.target.value
                                                )
                                            );
                                        }}
                                        onBlur={(event) => {
                                            handleCalcularPercentualPeloValor(
                                                event.target.value
                                            );
                                        }}
                                    />
                                    {errors && errors.valor_entrada && (
                                        <small className="form-text text-danger">
                                            É necessário um valor de entrada
                                        </small>
                                    )}
                                </InputGroup>
                                <span className="mensagem-porcentagem-entrada">
                                    Equivale a{" "}
                                    {formatDommus.formatarParaMascaraPercent(
                                        percentualEntrada
                                    )}
                                    % do valor devido
                                </span>
                            </div>
                            <div className="btns-entradas-section">
                                {percentuaisEntrada.map((item, index) => {
                                    return (
                                        <Button
                                            variant="secondary"
                                            kye={index}
                                            className={`btn-percentual-entrada ${
                                                Number(item.toFixed(2)) ===
                                                Number(percentualEntrada.toFixed(2))
                                                    ? "selecionado"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                handleAlterarEntrada(item);
                                            }}
                                        >
                                            {item}%
                                        </Button>
                                    );
                                })}
                                <Button
                                    variant="secondary"
                                    className={`btn-percentual-entrada ${
                                        Number(percentualEntrada.toFixed(0)) === 100
                                            ? "selecionado"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handleAlterarEntrada(100);
                                    }}
                                >
                                    à Vista
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-section">
                    <div className="tipo-pagamento-section">
                        <span>Informe a FORMA DE PAGAMENTO:</span>
                        <div className="btn-tipo-pagamento-section">
                            <div className="btn-tipo-pagamento-content">
                                <input
                                    type="radio"
                                    id="checkbox-cartao-credito"
                                    ref={register({ required: true })}
                                    name="tipo_pagamento"
                                    value="cartao_credito"
                                />
                                <label
                                    htmlFor="checkbox-cartao-credito"
                                    className={`checkbox-tipo-pagamento-label ${
                                        tipoPagamentoWatch === "cartao_credito"
                                            ? "checked"
                                            : ""
                                    }`}
                                >
                                    CARTÃO DE CRÉDITO
                                </label>
                            </div>
                            <div className="btn-tipo-pagamento-content">
                                <input
                                    type="radio"
                                    id="checkbox-boleto"
                                    ref={register({ required: true })}
                                    name="tipo_pagamento"
                                    value="boleto_bancario"
                                />
                                <label
                                    htmlFor="checkbox-boleto"
                                    className={`checkbox-tipo-pagamento-label ${
                                        tipoPagamentoWatch === "boleto_bancario"
                                            ? "checked"
                                            : ""
                                    }`}
                                >
                                    BOLETO BANCÁRIO
                                </label>
                            </div>
                        </div>
                    </div>
                    {errors && errors?.tipo_pagamento && (
                        <small className="form-text text-danger">
                            É necessário um uma forma de pagamento!
                        </small>
                    )}
                </div>
                {percentualEntrada && tipoPagamentoWatch ? (
                    <div className="item-section">
                        {Number(percentualEntrada.toFixed(0)) === 100 ? (
                            <div className="pagamento-a-vista-section">
                                <div className="valor-final-section">
                                    <span className="label"><strong>Valor</strong> com desconto para pagamento à vista:</span>
                                    <span className="valor-final">
                                        R$
                                        {formatarParaMascaraMoedaComSeparadorDeMilhares(
                                            valorFinalSaldoDevedor
                                        )}
                                    </span>
                                </div>
                                <div className="descontos-section">
                                    {percentualDescontos?.encargos && (
                                        <span>
                                            * Isenção de{" "}
                                            {percentualDescontos?.encargos}% dos
                                            encargos.
                                        </span>
                                    )}
                                    {percentualDescontos?.valor && (
                                        <span>
                                            * Desconto de{" "}
                                            {percentualDescontos?.valor}% do valor
                                            corrigido.
                                        </span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="parcelas-section">
                                <span>Selecione a parcela desejada:</span>
                                <Form.Group
                                    controlId="parcelas.ControlSelect1"
                                    className="mb-0"
                                >
                                    <Form.Control
                                        as="select"
                                        className="select-parcelas"
                                        name="parcela"
                                        ref={register({required: Number(percentualEntrada.toFixed(0)) !== 100})}
                                    >
                                        <option hidden value="">
                                            Parcelamentos disponíveis
                                        </option>
                                        {optionsParcelas.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {item}
                                                </Fragment>
                                            );
                                        })}
                                    </Form.Control>
                                    {errors && errors?.parcela && (
                                        <small className="form-text text-danger">
                                            É necessário a seleção do número de parcelas!
                                        </small>
                                    )}
                                    {!optionsParcelas.length ? (
                                        <small className="form-text text-danger">
                                            Valor de entrada mínima não atingido!
                                        </small>
                                    ) : (
                                        <></>
                                    )}
                                </Form.Group>
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
                <div className="footer-section">
                    <Form.Check
                        type="checkbox"
                        id={`default-proceguir`}
                        label="Estou ciente que os valores podem sofre alteração!"
                        onChange={(event) => {
                            setCheckboxCiencia(event.target.checked);
                        }}
                    />
                    <div className="btns-conclusao-section">
                        <Button
                            variant="success"
                            type="submit"
                            disabled={!checkboxCiencia || !negociacaoParametros?.contato_whatsapp}
                        >
                            Quero fechar acordo
                        </Button>
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>
            </>}
        </form>
    );
}
