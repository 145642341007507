import crm from "./crm";
import portal from "./portal";
import qs from "qs";

const configuracaoService = {
  buscarConfiguracoesVisuais: (slug=null, token=null) => {
    if(token && slug){
      return crm.get( "/configuracao/visuais", {
          baseURL: `${process.env.REACT_APP_CRM_API}/${slug}/`, 
          headers: {'Authorization' : token}
      });
    }else{
      return crm.get("/configuracao/visuais");
    }
  },
  buscarConfiguracoesVisuaisAuth: (slug) => {
    return portal.get(slug + "/configuracao/visuais/auth");
  },
  buscarConfiguracoesDesk: (slug, chaves) => {
    return portal.get(`${slug}/configuracao/desk/chaves`, {
      params: {chaves},
      paramsSerializer: params => qs.stringify(params, {arrayformat: 'repeat'})
    });
  },

};

export default configuracaoService;
