import React, { createContext } from "react";
import { AssistenciaTecnicaProvider } from "./AssistenciaTecnicaContext";
import { ConfiguracoesDeskProvider } from "./ConfiguracoesDeskContext";
import { ProcessoProvider } from "./ProcessoContext";
import { ConfiguracoesVisuaisContextProvider } from './ConfiguracoesVisuaisContext'

export const AppContext = createContext({});

export function AppContextProvider({ children }) {
    return (
        <AppContext.Provider value={{}}>
            <ConfiguracoesVisuaisContextProvider>
                <AssistenciaTecnicaProvider>
                    <ConfiguracoesDeskProvider>
                        <ProcessoProvider>
                            {children}
                        </ProcessoProvider>
                    </ConfiguracoesDeskProvider>
                </AssistenciaTecnicaProvider>
            </ConfiguracoesVisuaisContextProvider>
        </AppContext.Provider>
    );
}
