import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { Alert, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@iconify/react";
import Calculator from "@iconify-icons/dashicons/calculator";
import Whatsapp from "@iconify-icons/dashicons/whatsapp";
import ModalDommus from "../../../components/Modal";
import { ModalSimuladorFinanceiro } from "./ModalSimuladorFinanceiro";
import { trackPromise } from "react-promise-tracker";
import widgetService from "../../../services/WidgetService";
import guService from "../../../services/GuService";
import { formatarParaMascaraMoedaComSeparadorDeMilhares } from "../../../components/InputTextMask";
import formatDommus from "../../../helpers/format";
import { isMobile } from "react-device-detect";
import { ConfiguracoesVisuaisContextContext } from "../../../contexts/ConfiguracoesVisuaisContext";
import { LogNavegacaoService } from "../../../services/LogNavegacaoService";

export function SimuladorFinanceiro({ cor, idWidget, heigth }) {
    const [openModalSimulacao, setOpenModalSimulacao] = useState(false);
    const [valoresRenegociacao, setValoresRenegociacao] = useState();
    const [negociacaoParametros, setNegociacaoParametros] = useState();
    const { aplicarEstilosMobile } = useContext(ConfiguracoesVisuaisContextContext)
    const abrirLinkWhatsapp = () => {
        LogNavegacaoService.gravarLogNavegacao(LogNavegacaoService.NOME_COMPONENTE_WIDGET, idWidget, { acao: 'abriu link whatsapp' })
        let cpf = formatDommus.formatarParaMascaraCpf(guService.getLocalState("CPF"));
        let mensagem = `Quero negociar minha dívida!\nNome: ${guService.getLocalState("nomeProponente")} \nCPF: ${cpf}\n`
        mensagem += `*Saldo devedor: R$${formatarParaMascaraMoedaComSeparadorDeMilhares(valoresRenegociacao?.saldo_total_devedor)}*`
        window.open(`https://api.whatsapp.com/send?phone=${negociacaoParametros?.contato_whatsapp}&text=${encodeURIComponent(mensagem)}`, '_blank')
    }

    useEffect(()=>{
        const idProcesso = guService.getLocalState("Processo")
        trackPromise(widgetService.buscarValoresRenegociacao(idProcesso)).then((response)=>{            
            setValoresRenegociacao(response.data.data);
        }).catch((error)=>{
            setValoresRenegociacao(null);
        });
        trackPromise(
            widgetService.buscarParametrosSimuladorRenegociacao(idWidget)
        )
            .then((response) => {
                setNegociacaoParametros(response.data);
            })
            .catch((error) => {
                setValoresRenegociacao(null);
            });
    },[])

    function abrirModalSimulacao() {
        LogNavegacaoService.gravarLogNavegacao(LogNavegacaoService.NOME_COMPONENTE_WIDGET, idWidget, { acao: 'abriu modal de simulação' })
        setOpenModalSimulacao(true)
    }

    return (
        valoresRenegociacao ? 
        <>
            {(isMobile && aplicarEstilosMobile && (<>
                <Row className="simulador-financeiro-content">
                    <Col>
                        <Row className="px-3">
                            <FontAwesomeIcon icon={faHandHoldingUsd} className="icone-simulador mr-3" style={{ fontSize: '35pt' }}/>
                            <div className="item-section">
                                <span className="label-item">Saldo Devedor:</span>
                                <span className="saldo-devedor" style={{ fontSize: '20pt' }}>
                                    R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(valoresRenegociacao?.saldo_total_devedor ?? "")}
                                </span>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="simulador-financeiro-content">
                    <Col className="btns-section">
                        <button className="btn btn-simular-agora" onClick={abrirModalSimulacao}>
                            <Icon icon={Calculator} />
                            <span>Simular agora</span>
                        </button>
                        <button className="btn btn-quero-negociar" disabled={!negociacaoParametros?.contato_whatsapp} onClick={abrirLinkWhatsapp}>
                            <Icon icon={Whatsapp} />
                            <span>Quero negociar</span>
                        </button>
                    </Col>
                </Row>
            </>)) || <div className="simulador-financeiro-content">
                <FontAwesomeIcon
                    icon={faHandHoldingUsd}
                    className="icone-simulador"
                />
                <div className="item-section">
                    <span className="label-item">Saldo Devedor:</span>
                    <span className="saldo-devedor">R$ {formatarParaMascaraMoedaComSeparadorDeMilhares(valoresRenegociacao?.saldo_total_devedor ?? "")}</span>
                </div>
                <div className="btns-section">
                    <button
                        className="btn btn-simular-agora"                              
                        onClick={abrirModalSimulacao}
                    >
                        <Icon icon={Calculator} />
                        <span>Simular agora</span>
                    </button>
                    <button className="btn btn-quero-negociar" disabled={!negociacaoParametros?.contato_whatsapp} onClick={abrirLinkWhatsapp}>
                        <Icon icon={Whatsapp} />
                        <span>Quero negociar</span>
                    </button>
                </div>
            </div>}
            <ModalDommus
                open={openModalSimulacao}
                close={() => {
                    setOpenModalSimulacao(false);
                }}
                titulo={"Renegociar valor em atraso"}
                className={"modal-simulador-negociacao"}
            >
                <ModalSimuladorFinanceiro 
                    valoresRenegociacao={valoresRenegociacao}
                    idWidget={idWidget}
                    closeModal={()=>{setOpenModalSimulacao(false)}}
                    negociacaoParametros={negociacaoParametros}
                />
            </ModalDommus>
        </>
        : <Alert variant="warning">Não foi possível buscar os valores em atraso!</Alert>
    );
}
