import React, { createContext, useEffect, useState } from "react";

export const ConfiguracoesVisuaisContextContext = createContext({});

export function ConfiguracoesVisuaisContextProvider({ children }) {
    const [listaConfiguracoes, setListaConfiguracaoes] = useState([])
    const [aplicarEstilosMobile, setAplicarEstilosMobile] = useState(true)
    const obterConfiguracaoPorChave = (chave) => listaConfiguracoes.find(configuracao => configuracao.chave === chave)?.valor

    useEffect(() => {
        setAplicarEstilosMobile(obterConfiguracaoPorChave('aplicar_estilos_mobile'))
    }, [listaConfiguracoes])

    return (
        <ConfiguracoesVisuaisContextContext.Provider value={{ setListaConfiguracaoes, obterConfiguracaoPorChave, aplicarEstilosMobile }}>
            {children}
        </ConfiguracoesVisuaisContextContext.Provider>
    );
}
