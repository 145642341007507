import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import './index.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import AnexoIcone from "../AnexoIcone";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { errorToast } from "../DommusToast";
import { SwapVertTwoTone } from "@material-ui/icons";

export function DommusMultiFileInput({maximoArquivos=1, extensoesPermitidas, setArquivos, setArquivosFormatados, tipoUpload='area'}){
  const [lista, setLista] = useState([]);
  const [listaArquivosFormatados, setListaArquivosFormatados] = useState([]);
  
  const filesTypes = useMemo(()=>{
    if(extensoesPermitidas && extensoesPermitidas.length){
      return extensoesPermitidas.join(',');
    }else{
      return '.pdf';
    }
  },[extensoesPermitidas])

  useEffect(()=>{
    if(setArquivos){
      setArquivos(lista);
    }

    if(setArquivosFormatados){
      setArquivosFormatados(listaArquivosFormatados)
    }
  },[lista, listaArquivosFormatados])

  const removeAnexo = useCallback((idArquivo) => {
    
    const novaListaFiles = [...lista];
    novaListaFiles.splice(idArquivo, 1);
    setLista(novaListaFiles);
    const novaListaFormatada = [...listaArquivosFormatados];
    novaListaFormatada.splice(idArquivo, 1);
    setListaArquivosFormatados(novaListaFormatada);

  },[lista, listaArquivosFormatados]);

  const handleVerifyType = (file) => {
        const extensao = file.name.split('.').pop();
        if (extensoesPermitidas.includes(`.${extensao}`)) {
            return extensao;
        } else {
            return extensao
        }
  };

  const adicionarArquivoFormado = (file) => {
    let reader = new FileReader();
    
    reader.onload = function (upload) {
      let dadosArquivo = {
        conteudo: upload.target.result.split(/base64,/)[1],
        nome: file.name,
      };
      setListaArquivosFormatados((current) => [...current, dadosArquivo]);
    };

    reader.readAsDataURL(file);

  }

  async function onDrop(acceptedFile) {        
      if(acceptedFile){
          if(((lista.length ?? 0) + (acceptedFile.length ?? 0)) > maximoArquivos){
            errorToast.fire('Nível máximo de arquivos atingido!');
            return;
          }

          let arquivoUltrapassou = false;

          acceptedFile.forEach(file => {
              if( Math.round((file.size / 1024)) > 10000){
                  arquivoUltrapassou = true;
                  return;
              }
          });            

          if(arquivoUltrapassou){
              Swal.fire({
                  titleText: "Ooops...",
                  text: "Arquivo com tamanho não suportado. O máximo é de 5Mb",
                  icon: "warning",
              });
          }else{
              let listTemp = [...lista, ...acceptedFile];
              setLista(listTemp);
              acceptedFile.forEach((file)=>{
                adicionarArquivoFormado(file);
              })
          }
      }     
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, maxFiles: maximoArquivos });

  useEffect(()=>{
    if(fileRejections.length && fileRejections[0]?.errors[0]?.code){
      let item = fileRejections[0]?.errors[0]?.code;
      let mensagem = '';
      switch(item){
        case 'too-many-files':
          mensagem = 'Nível máximo de arquivos atingido!';
          break;
        default:
          mensagem = 'Erro ao carregar arquivo!';
          break;
      }
      errorToast.fire(mensagem);
    }
  },[fileRejections])

  const tipoUploadComponent = useMemo(()=>{
    switch(tipoUpload){
      case 'icone':
        return(
          <div {...getRootProps()} className="upload-icone">
              <input {...getInputProps()} accept={filesTypes}/>
              <FontAwesomeIcon icon={faPlus}/>
          </div>
        );
      case 'area':
      default:
        return(
          <div {...getRootProps()} className="upload-area">
              <input {...getInputProps()} accept={filesTypes}/>
              <p>Arraste ou clique para carregar um arquivo. Máximo 10Mb</p>
          </div>
        )
    }
  },[tipoUpload, lista])

  return (
    <div className="files-section">
      {tipoUploadComponent}
      {
          lista && lista.map((file, index) => (          
              <div className='item-card' key={index}>
                  <div className='preview-item'>
                      {handleVerifyType(file) === true
                          ? (<>
                              < img className="img_upload" src={URL.createObjectURL(file)} alt={file.name} />
                          </>
                          )
                          :
                          <AnexoIcone anexo={handleVerifyType(file)} style={{ fontSize: "10rem" }} />
                      }
                  </div>                        
                  <div className='header-item-card'>
                      <OverlayTrigger
                          key={index}
                          placement="top"
                          overlay={
                              <Tooltip id={String(index)}>
                                  {file ? file.name : " "}
                              </Tooltip>
                          }
                      >
                          <span className='item-name' data-tip={file?.name ?? ""}>
                              {file ? file.name : " "}
                          </span>                                
                      </OverlayTrigger>
                      <span className='btn-remove-item' onClick={() => removeAnexo(index)}>
                          <FontAwesomeIcon icon={faTrash}/>
                      </span>
                  </div>
              </div>
          ))
      }
    </div>
  )
}
