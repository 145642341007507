import moment from "moment";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import widgetService from "../../../services/WidgetService";
import guService from "../../../services/GuService";
import { Alert } from "react-bootstrap";
import { DommusThumbnail } from "../../../components/DommusThumbnail";
import "./index.css";
import Swal from "sweetalert2";
import { LogNavegacaoService } from "../../../services/LogNavegacaoService";

export function Arquivos({ cor, idWidget, heigth }) {
    const [listaArquivos, setListaArquivos] = useState([]);
    moment.locale("pt-br");

    useEffect(() => {
        let referencia = "";
        let idReferencia = "";
        let processo = null;

        if (guService.getLocalState("usuarioTipo") === 'SC') {
            referencia = "empreendimento";
            idReferencia = guService.getLocalState("IdEmpreendimento");
        } else {
            referencia = "proponente";
            idReferencia = guService.getLocalState("CPF");
            processo = guService.getLocalState("Processo");
        }
        trackPromise(widgetService.buscarArquivosPorWidgetReferencia(idWidget, referencia, idReferencia, processo)).then((response)=>{
          setListaArquivos(response.data);
        }).catch((error)=>{
          Swal.fire({
            titleText: "Ooops... Erro ao buscar documentos!",
            text: "Error: " + error,
            icon: "error",
          });
        })
    }, []);

    return(
      <div style={{minHeight: heigth ?? '100px'}}>
      {
        listaArquivos.length ? (
          <div className="arquivos-section">
              {listaArquivos.map((arquivo, index) => {
                  return (
                      <div key={index} className="arquivo-bloco">
                          <div 
                              className="arquivo-thumb" 
                              onClick={() => LogNavegacaoService.gravarLogNavegacao(LogNavegacaoService.NOME_COMPONENTE_WIDGET, idWidget, { idArquivo: arquivo.id })}
                          >
                            <DommusThumbnail url={arquivo?.url} />
                          </div>
                          <span className="arquivo-label" data-tip={arquivo?.descricao ?? ""}>{arquivo?.descricao ?? ""}</span>
                      </div>
                  );
              })}
          </div>
        ) : (
            <Alert variant="warning"> Nenhum Arquivo encontrado</Alert>
        )
      }
    </div>
  )
}
