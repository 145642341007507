import React, { useEffect, useState } from "react";
import { Accordion, Alert, Card } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import widgetService from "../../../services/WidgetService";
import Swal from "sweetalert2";
import guService from "../../../services/GuService";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { LogNavegacaoService } from "../../../services/LogNavegacaoService";

export function Faq({idWidget, cor, heigth}) {
    const [dadosFaq, setDadosFaq] = useState(null);
    const [cardOpen, setCardOpen] = useState("");

    useEffect(() => {
        trackPromise(widgetService.buscarDadosFaq(idWidget, guService.getLocalState('IdEmpreendimento'))).then((response)=>{
            setDadosFaq(response.data);
        }).catch((error)=>{
            Swal.fire({
                titleText: "Erro!",
                text: "Erro ao carregar FAQ",
                icon: "error",
            });
        })        
    }, []);

    const handleCardOpen = (key)=>{
        setCardOpen(key === cardOpen? "" : key);
    }

    return dadosFaq && dadosFaq?.perguntas.length ? (
        <div style={{maxHeight: heigth ?? "500px", overflowY: "auto"}}>
            <Accordion activeKey={cardOpen}>
                {dadosFaq?.perguntas.map((item, index)=>{
                    return (
                        <Card key={index} className="card-faq">
                            <Accordion.Toggle 
                                as={Card.Header} 
                                eventKey={index.toString()} 
                                className="header-card-faq" 
                                onClick={() => {
                                    LogNavegacaoService.gravarLogNavegacao(LogNavegacaoService.NOME_COMPONENTE_WIDGET, idWidget, { idPergunta: item.id })
                                    handleCardOpen(index.toString())
                                }}
                            >
                                {item.pergunta}
                                {cardOpen === index.toString() ?
                                    <FontAwesomeIcon icon={faChevronUp}/>
                                    :
                                    <FontAwesomeIcon icon={faChevronDown}/>
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body className="body-faq">
                                    <div dangerouslySetInnerHTML={{__html:item.resposta}}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        </div>
    ) : (
        <Alert variant="warning">Nenhuma pegunta encontrada!</Alert>
    );
}
