import React from "react";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ApresentacaoEmpreendimento({ cor, dados }) {
  return dados.map((item, index) => {
    return (
      <div className="row pl-3 pr-4" style={{ display: "flex" }} key={index}>
        <Col sm={12} lg={4} className="pl-0 pr-4">
          {item.empreendimento_imagem_apresentacao ? (
            <img
              alt="Imagem empreendimento"
              className="boxImage mb-3"
              src={item.empreendimento_imagem_apresentacao}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          ) : (
            <div
              className="boxImage mb-3"
              style={{
                width: 350,
                height: 350,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faImage} size="3x" />
            </div>
          )}
        </Col>
        <Col className="pl-lg-5">
          <Row>
            <p>
              <h1 style={{textTransform: 'capitalize'}}>{item.empreendimento_nome.toLowerCase()}</h1>
            </p>
          </Row>
          <Row>
            <b>Total de Unidades: </b>{" "}
            <span style={{ marginLeft: 10 }}>480</span>
          </Row>
          {item.previsao_entrega && (
            <Row>
              <b>Previsão de Entrega: </b>{" "}
              <span style={{ marginLeft: 10 }}>{moment(item.previsao_entrega).format('DD/MM/YYYY')}</span>
            </Row>
          ) || <></>}
          <Row>
            <b>Endereço: </b>
            <span style={{ marginLeft: 10 }}>
              {item.empreendimento_endereco +
                ", " +
                item.empreendimento_numero +
                ", " +
                item.empreendimento_bairro +
                ", " +
                item.empreendimento_cidade +
                "/" +
                item.empreendimento_uf +
                " - "}
              <b>CEP: </b>
              {item.empreendimento_cep}
            </span>
          </Row>
          <Row className="info-empreendimento-section">
            <div>
              <h4>Lazer completo sem precisar sair de casa</h4>
              <p>Piscinas adulto e infantil, espaço gourmet com churrasqueira, campo gramado, academia, playground, street ball e muito mais.</p>
            </div>
            <div>
              <h4>Viva em um bairro completo para o seu dia a dia</h4>
              <p>A região de Justinópolis tem a melhor infraestrutura comercial do vetor norte. Tem shoppings, estação do Move, farmácias, bancos, comercio variado e muito mais...</p>
            </div>
            <div>
              <h4>Acesso fácil para você chegar com tranquilidade</h4>
              <p>Acesso rápido pelas avenidas Padre Pedro Pinto, Vilarinho e Cristiano Machado. Inúmeras linhas de ônibus atendem a região, inclusive com uma estação do Move perto.</p>
            </div>
            <div>
              <h4>Segurança e conforto para você e sua família</h4>
              <p>Condomínio seguro com guarita para porteiro 24 horas, ótima estrutura de lazer com espaços diferenciados para crianças, adultos e para terceira idade.</p>
            </div>
          </Row>
        </Col>
      </div>
    );
  });
}
