/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../LoginCliente/index.css";
import TelaLogin from "./TelaLogin";
import { trackPromise } from "react-promise-tracker";
import GuService from "../../services/GuService";
import usuarioService from "../../services/UsuarioService";
import configuracaoService from "../../services/ConfiguracaoService";
import { useParams } from "react-router";
import ContatoEscolhido from "./ContatoEscolhido";
import TokenValidation from "./TokenValidation";
import ModalDommus from "../../components/Modal";
import Swal from "sweetalert2";
import guService from "../../services/GuService";
import empreendimentoService from "../../services/EmpreendimentoService";

export default function Login({ history }) {
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [logoCliente, setLogoCliente] = useState("");
  const [corPrimaria, setCorPrimaria] = useState("");
  const [corSecundaria, setCorSecundaria] = useState("");
  const [logoBranca, setLogoBranca] = useState(false);
  const [corFundo, setCorFundo] = useState("");
  const [imagemFundo, setImagemFundo] = useState(null);
  const [error, setError] = useState(false);
  const [dados, setDados] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [dadosCliente2Factor, setDadosCliente2Factor] = useState([]);
  const [loadingDados, setLoadingDados] = useState(false);
  const [tipoContato, setTipoContato] = useState('SMS')
  const [contact, setContact] = useState("");
  const [loadingDadosContact, setLoadingDadosContact] = useState(false);
  const [tokenEnviado, setTokenEnviado] = useState(false);
  const [openModal2Factor, setOpenModal2Factor] = useState(false);
  const [cnpjSindico, setCnpjSindico] = useState(false)
  const [loadingDadosToken, setLoadingDadosToken] = useState(false);
  const [code, setCode] = useState("");
  const  [appBadges,setAppBadges] = useState({ios:'',android:''})
  const [imagemFundoMobile, setImagemFundoMobile] = useState(null)
  const [listaTelefones, setListaTelefones] = useState([]);
  const [listaEmail, setListaEmail] = useState([]);

  let params = useParams();
  const slug = params.slug;
  useEffect(() => {
    localStorage.clear();
    GuService.setLocalState("slug", slug);
    GuService.setLocalState("idUsuario", 1);
  }, [slug]);

  useEffect(() => {
    configuracaoService.buscarConfiguracoesVisuaisAuth(slug).then((res) => {
      setDados(res.data);
      let linksApps = {ios:'',android:''}
      res.data.forEach((configuracao) => {
        GuService.setLocalState(configuracao.chave, configuracao.valor);
        if (configuracao.chave == "controle_financeiro") {
          GuService.setLocalState(
            configuracao.chave,
            JSON.stringify(configuracao.valor)
          );
        }
        if (configuracao.chave != "cpf_validacao") {
          if (configuracao.chave == "logo_login") {
            setLogoCliente(configuracao.valor);
          } else if (configuracao.chave == "cor_primaria") {
            setCorPrimaria(configuracao.valor);
          } else if (configuracao.chave == "cor_fundo") {
            setCorFundo(configuracao.valor);
          } else if (configuracao.chave == "imagem_fundo") {
            setImagemFundo(configuracao.valor);
          } else if (configuracao.chave == `logo_cliente_${slug}`) {
            setLogoCliente(configuracao.valor);
          } else if (configuracao.chave == `imagem_fundo_${slug}`) {
            setImagemFundo(configuracao.valor);
          }else if (configuracao.chave == "logo_branca") {
            if(configuracao.valor == true){
              var r = document.querySelector(":root");
              r.style.setProperty(
                  "--dommus-cor-logo",
                  'brightness(0) invert(1)'
              );
            }
          }else if(configuracao.chave == 'app_store_link'){
            linksApps.ios = configuracao.valor;
          }else if(configuracao.chave == 'play_store_link'){
            linksApps.android = configuracao.valor
          } else if (configuracao.chave == 'imagem_fundo_mobile') {
            setImagemFundoMobile(configuracao.valor);
          }
        }
      });

console.log(linksApps)
      setAppBadges(linksApps)
    });
  }, [slug]);

  function limpaCpf(cpf) {
    const cfpLimpo = cpf.match(/\d/g).join("");
    return cfpLimpo;
  }

  function handleSubmit(data) {
    setLoadingDados(true);    
    setCpf(data.cpf);
    trackPromise(
      usuarioService.getContacts(limpaCpf(data.cpf), slug).then(
        (response) => {
          if (response.data === 401) {
            setLoadingDados(false);
            console.log(`ERROR`);
          } else {
            if(!(Array.isArray(response.data) && response.data.length)){
              setLoadingDados(false);
              Swal.fire({
                titleText: "Erro.",
                text: "Não conseguimos achar os dados, tem certeza que digitou o CPF correto?",
                icon: "error",
              });
              return false;
            }
            setDadosCliente2Factor(response.data);
            let emailArr = [];
            let telefoneArr = [];
            if (response.data) {
              response.data.map((item) => {
                if (item.tipo === "email") {
                  emailArr.push(item);
                } else {
                  telefoneArr.push(item);
                }
              });
            }
            setListaEmail(emailArr);
            setListaTelefones(telefoneArr);
            setOpenModal2Factor(true);
            setLoadingDados(false);
          }
        },
        (error) => {
          setLoadingDados(false);
          Swal.fire({
            titleText: "Erro.",
            text: "Não conseguimos achar os dados, tem certeza que digitou o CPF correto?",
            icon: "error",
          });
        }
      ),
      "no-area"
    );
  }

  function handleSubmitContact(event) {
    event.preventDefault();
    setLoadingDadosContact(true);
    trackPromise(
      usuarioService.getCode(contact, slug, cpf, tipoContato.toLowerCase(), cnpjSindico).then(
        (response) => {
          if (response.status === 500) {
            setLoadingDadosContact(false);
            console.log(`ERRO: ${response?.data}`);
          } else {
            setLoadingDadosContact(false);
            setOpenModal2Factor(false);
            setTokenEnviado(true);
          }
        },
        (error) => {
          setLoadingDadosContact(false);
          let msg = 'Não conseguimos enviar o código de verificação, verifique se os dados estão corretos!'
          if(error?.response?.status == 429) {
            msg = 'Você atingiu o limite de tentativas de autenticação. Aguarde 1 hora e tente novamente.'
          }
          Swal.fire({
            titleText: "Erro.",
            text: msg,
            icon: "error",
          });
        }
      ),
      "no-area"
    );
  }

  function handleSubmitToken(event) {
    event.preventDefault();
    setLoadingDadosToken(true);
    let dados = {
      cpf: limpaCpf(cpf),
      code: code,
      tipoContato: tipoContato.toLowerCase(),
      sindico: cnpjSindico
    };
    trackPromise(
      usuarioService.postAutenticate(contact, slug, dados).then(
        (response) => {
          if (response.data === 401) {
            setLoadingDadosToken(false);
            console.log(`ERROR`);
          } else {
            setLoadingDadosToken(false);
            const { cpf, id_usuario, nome, token, tipo_usuario } = response.data;
            GuService.setLocalState("idUsuario", id_usuario);
            GuService.setLocalState("usuarioTipo", tipo_usuario);
            GuService.setLocalState("slug", slug);
            GuService.setLocalState("CPF", cpf);
            GuService.setLocalState("Nome", nome);
            GuService.setLocalState("token", token);
            guService.setLocalState('IdEmpreendimento', Number(contact))
            try{
              document.cookie = "TUPCD_24=" + token + "; Domain=dommus.com.br; Secure; Path=/;";
            }catch(error){
              console.log(error)
            }
            history.push("/");
            window.location.reload()
          }
        },
        (error) => {
          setLoadingDadosToken(false);
          let msg = 'Erro ao validar o token, verifique se o mesmo está correto!'
          if(error?.response?.status == 429) {
            msg = 'Você atingiu o limite de tentativas de autenticação. Aguarde 1 hora e tente novamente.'
          }
          Swal.fire({
            titleText: "Erro.",
            text: msg,
            icon: "error",
          });
        }
      ),
      "no-area"
    );
  }

  return (
    <>
      {dados ? (
        <TelaLogin
          logoCliente={logoCliente}
          corPrimaria={corPrimaria}
          corSecundaria={corSecundaria}
          corFundo={corFundo}
          imagemFundo={imagemFundo}
          slug={slug}
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage}
          error={error}
          handleOnSubmit={handleSubmit}
          loadingDados={loadingDados}
          appsBadges={appBadges}
          imagemFundoMobile={imagemFundoMobile}
        />
      ) : (
        window.location.reload()
      )}
      <ModalDommus
        open={openModal2Factor}
        className="modalchamado"
        size={
          listaTelefones.length >= 1 && listaEmail.length >= 1 ? "lg" : "md"
        }
        close={() => {
          setOpenModal2Factor(false);
        }}
        titulo={`Acessar Portal do Cliente`}
      >
        <ContatoEscolhido
          logoCliente={logoCliente}
          corPrimaria={corPrimaria}
          corSecundaria={corSecundaria}
          corFundo={corFundo}
          imagemFundo={imagemFundo}
          slug={slug}
          setContact={setContact}
          contact={contact}
          errorMessage={errorMessage}
          error={error}
          handleSubmit={handleSubmitContact}
          loadingDados={loadingDadosContact}
          listaContatos={dadosCliente2Factor}
          tipoContato={tipoContato}
          setTipoContato={setTipoContato}
          habilitaValidacaoWhatsapp={dados.find(dado => dado.chave == 'habilita_validacao_whatsapp')?.valor}
          setCnpjSindico={setCnpjSindico}
        />
      </ModalDommus>
      <ModalDommus
        open={tokenEnviado}
        className="modalchamado"
        size={"md"}
        close={() => {
          setTokenEnviado(false);
        }}
        titulo={`Acessar Portal do Cliente`}
      >
        <TokenValidation
          logoCliente={logoCliente}
          corPrimaria={corPrimaria}
          corSecundaria={corSecundaria}
          corFundo={corFundo}
          imagemFundo={imagemFundo}
          slug={slug}
          setCode={setCode}
          code={code}
          errorMessage={errorMessage}
          error={error}
          handleSubmit={handleSubmitToken}
          loadingDados={loadingDadosToken}
          handleSubmitContact={handleSubmitContact}
        />
      </ModalDommus>
    </>
  );
}
